
import {
  companiesMapActions,
  companiesMapGetters,
  companiesMapMutations
} from "@/store/modules/companies";
import * as types from "@/store/mutation-types";
import { companyForm } from "@/forms/admin/company";
import { FormBlock } from "@/components/FormBuilder/types";
import { ICompany } from "@/store/modules/companies/types";
import * as selectOptions from "@/helpers/selectOptions";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import FormBuilderDataCollector from "@/components/FormBuilder/FormBuilderDataCollector.vue";

import { objectDifference } from "@/helpers";
import CompanyMixin from "./CompanyMixin";
import VueWithMixins from "@/helpers/mixins";

export default VueWithMixins(CompanyMixin, ActivitiesMixin).extend({
  name: "SingleCompanyView",

  components: {
    FormBuilderDataCollector
  },
  created() {
    //we'll want to make request to api to fetch company
    this.getOneCompany(this.companyId)
      .then(() => {
        this.SET_EDIT(this.company);
      })
      .catch(error => {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      });
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  methods: {
    ...companiesMapActions(["getOneCompany", "updateCompany", "deleteCompany"]),
    ...companiesMapMutations(["SET_EDIT", "SET_COMPANY_FORM_TYPES"]),
    ...companiesMapMutations({ editField: types.SET_EDIT_FIELD }),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "save":
          this.promptUpdateConfirmation();
          break;
        case "cancelEditingCompany":
          this.companiesCallback();
          break;
      }
    },
    async deleteCallback(): Promise<void> {
      try {
        this.$modal.show("companiesSingleDeleteModal");
      } catch (error) {
        // delete disabled
      }
    },
    async promptUpdateConfirmation(): Promise<void> {
      try {
        this.$modal.show("companiesSingleEditModal");
      } catch (error) {
        this.$bugSnagClient.notify(error);
      }
    },
    async companiesCallback(): Promise<any> {
      this.$router.push("/admin/companies").catch(() => {});
    },
    async deleteOneCompany(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Deleting company. Please wait...";
        await this.deleteCompany(this.companyId);
        this.$appNotifySuccess("Company Successfully Deleted");
        this.companiesCallback();
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    async updateCallback(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Updating company. Please wait...";
        await this.updateCompany({
          id: this.companyId,
          update: this.updatedFields
        });
        this.$appNotifySuccess("Company Updated Successfully");
        this.companiesCallback();
      } catch (err) {
        this.errorMessage = "Operation specified is not supported";
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    formFieldChangeHandler({ key, value }: { key: any; value: any }) {
      if (key === "structuredAfter" && value) {
        const number4Companies = [18, 23, 24, 26, 28, 30];
        const fieldsToFill = ["companyLineOfBusiness", "site"];
        const initData = this.$store.state.auth["initData"];
        const { companies = [] } = initData;
        const matchedCompany = companies.find((company: ICompany) => {
          return company.companyNumber === value;
        });

        this.doEditField("mCompany", number4Companies.includes(value) ? 4 : 1);

        for (let field of fieldsToFill) {
          this.doEditField(field as keyof ICompany, matchedCompany[field]);
        }
      }
      if (key === "formTypes") {
        this.SET_COMPANY_FORM_TYPES(value);
      } else {
        this.doEditField(key, value);
      }
    }
  },
  computed: {
    ...companiesMapGetters(["getCompanyById", "editingCompany"]),
    companyId(): string {
      return this.$route.params.companyId;
    },
    company(): any {
      return this.getCompanyById(this.companyId);
    },
    updatedFields(): any {
      const edited = this.editingCompany;
      const original = this.company;
      return edited && original ? objectDifference(edited, original) : {};
    },
    formIsValid(): boolean {
      return Object.keys(this.updatedFields).length > 0;
    },
    companyFields(): FormBlock[] {
      return companyForm(this.companyOptions, this.lineOfBusinessOptions);
    },
    companyOptions(): { label: string; value: string }[] {
      const initData = this.$store.state.auth["initData"];
      const { companies = [] } = initData;
      const options = companies.map((company: ICompany) => ({
        label: company.companyName,
        value: company.companyNumber
      }));
      return options;
    },
    lineOfBusinessOptions(): any[] {
      if (this.editing?.mCompany === 27) {
        return selectOptions.companyLineOfBusiness.slice(0, 2);
      }
      return selectOptions.companyLineOfBusiness;
    },
    queryOverride(): any {
      return {
        "data.companyId": this.editing?._id,
        activityType: "CompanyActivity"
      };
    }
  }
});
